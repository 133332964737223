@import "~bootstrap/scss/bootstrap";

@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@300;500;700;900&display=swap");

$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";

@font-face {
    font-family: "Cairo", sans-serif;
}

$primary-color: #003c62;
$secondary-color: #004e7f;

$header-color: $primary-color;
$header-logo-color: #fff;

* {
    font-family: "Cairo", sans-serif;
}

html {
    background-color: #fff;
}

body {
    padding-top: 50px;

    @include media-breakpoint-up(lg) {
        padding-top: 111px;
    }
}

h1,
h2,
h3,
h4,
h5 {
    font-family: "Cairo", sans-serif;
}

.section {
    overflow: hidden;
    padding: 5rem 0;
    position: relative;
    background-color: #fff;
}

.section-anchor {
    position: relative;
    top: -100px;
}

.heading-wrapper {
    margin-bottom: 2rem;

    &.template-1 {
        .subheading {
            color: #fff;
            display: inline-block;
            padding: 0.25rem 0.75rem;
            position: relative;
            text-transform: uppercase;

            span {
                position: relative;
            }

            &:before {
                content: "";
                display: block;
                position: absolute;
                z-index: 0;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: $primary-color;
                transform: skew(-10deg, 0deg);
                transition: top 0.3s ease;
            }
        }

        .heading {
            font-size: 2.5rem;
            color: $primary-color;

            &:after {
                content: "";
                display: block;
                margin-top: 1rem;
                position: relative;
                width: 60px;
                height: 6px;
                background-color: $primary-color;
                transform: skew(-10deg, 0deg);
            }
        }

        &.text-center {
            .heading {
                &:after {
                    margin: 1rem auto 0;
                }
            }
        }

        &.text-white {
            .subheading {
                color: $primary-color;

                &:before {
                    background-color: #fff;
                }
            }

            .heading {
                color: #fff;

                &:after {
                    background-color: #fff;
                }
            }
        }
    }

    &.template-2 {
        position: relative;
        margin-bottom: 1rem;

        .heading {
            padding-left: calc(0.5rem + 6px);

            &:before {
                content: "";
                display: block;
                width: 6px;
                height: 100%;
                border-left: 4px solid $primary-color;
                border-top: 2px solid transparent;
                border-bottom: 2px solid transparent;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
}

#header {
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0.25em 1.5em rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    z-index: 10;

    .navbar-brand {
        max-width: calc(100% - 100px);

        img {
            max-width: 100%;
            height: auto;
        }
    }

    .navbar-toggler {
        border-radius: 0;
        padding: 10px 15px;
        font-size: 1.25rem;
    }

    .navbar-nav {
        .nav-item {
            margin-left: 0.5rem;

            &.nav-flags {
                text-align: center;
            }

            a.nav-link {
                position: relative;
                text-align: center;
                border-bottom: 1px solid #ddd;

                @include media-breakpoint-up(lg) {
                    border-bottom: none;
                }

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    z-index: -1;
                    top: 100%;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: $primary-color;
                    transform: skew(-10deg, 0deg);
                    transition: top 0.3s ease;
                }

                &.active,
                &:hover {
                    color: #fff;

                    &:before {
                        top: 0;
                    }
                }
            }

            a.nav-icon {
                display: inline-block;
                margin: 8px 0;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.35);
                transition: box-shadow ease 0.3s;

                img {
                    display: block;
                }

                &:hover {
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
                }
            }
        }
    }
}

#homepage-slider {
    padding: 0;
    position: relative;

    .homepage-slider-bg {
        padding: 0;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(../img/homepage-slider-7.webp);
        background-position: center center;
        background-size: cover;
        overflow: hidden;

        @include media-breakpoint-up(md) {
            background-attachment: fixed;
        }
    }

    &:before {
        content: "";
        background-image: linear-gradient(to left, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4));
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &:after {
        content: "";
        width: 100%;
        height: 50%;
        background-color: $primary-color;
        border-bottom: 20px solid $primary-color;
        background-image: linear-gradient(220deg, lighten($primary-color, 5%), darken($primary-color, 2%));
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        transform: skewY(-5deg);
        opacity: 0.9;

        @include media-breakpoint-up(md) {
            height: 52%;
        }

        @include media-breakpoint-up(lg) {
            width: 55%;
            height: 100%;
            border-right: 20px solid $primary-color;
            top: 0;
            left: -100px;
            transform: skewX(12.5deg);
        }
    }

    .first-column {
        padding: 50px 50px;
        margin: 50px 0;
        color: #fff;
        position: relative;
        z-index: 4;

        @include media-breakpoint-up(md) {
            padding: 75px 50px;
            margin-bottom: 0;
        }

        @include media-breakpoint-up(lg) {
            padding: 75px;
            margin: 100px 0;
        }
    }

    .second-column {
        padding: 20px 50px 50px 75px;
        margin: 0 0;
        position: relative;
        z-index: 2;

        @include media-breakpoint-up(lg) {
            padding: 75px;
            margin: 100px 0;
        }

        ul {
            list-style: none;
            display: block;
            margin: 0;
            padding: 0;

            li {
                color: #fff;
                font-size: 1.75rem;
                margin-bottom: 0.75rem;
                font-weight: bold;
                position: relative;

                &:before {
                    content: "";
                    display: block;
                    width: 15px;
                    height: 30px;
                    background: $secondary-color;
                    background-image: linear-gradient(220deg, lighten($primary-color, 5%), darken($primary-color, 2%));
                    position: absolute;
                    top: 50%;
                    left: -30px;
                    transform: skewX(12.5deg) translateY(-50%);
                }

                &:nth-child(1) {
                    margin-left: 0;
                }

                &:nth-child(2) {
                    margin-left: 15px;
                }

                &:nth-child(3) {
                    margin-left: 30px;
                }
            }
        }
    }
}

#homepage-about {
    .about-content {
        p {
            font-size: 18px;
            line-height: 30px;
            color: #5e5e5e;
        }
    }
}

#homepage-partners,
#homepage-partners2 {
    background-image: url(../img/homepage-partners.webp);
    background-attachment: fixed;
    background-position: center;
    background-size: cover;

    .partners {
        .partner-wrapper {
            .partner-inner {
                text-align: center;
                background-color: #fff;
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-bottom: 4px solid $primary-color;
                overflow: hidden;

                a {
                    display: block;
                    position: relative;

                    &:before {
                        content: "";
                        background-color: $primary-color;
                        background-image: linear-gradient(
                            220deg,
                            lighten($primary-color, 5%),
                            darken($primary-color, 2%)
                        );
                        position: absolute;
                        z-index: 3;
                        top: 100%;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        z-index: 1;
                        transition: top ease 0.3s;
                    }

                    strong {
                        display: block;
                        padding: 10rem;
                        font-size: 1.25rem;
                        text-wrap: nowrap;
                        border: 1px solid #fff9;
                        position: absolute;
                        z-index: 2;
                        top: 50%;
                        left: 50%;
                        color: #fff;
                        transform: translate(-50%, -50%);
                        opacity: 0;
                        transition: all 0.3s ease;
                    }

                    &:hover {
                        &:before {
                            top: 0;
                        }

                        strong {
                            padding: 1rem 2rem;
                            opacity: 0.9;
                        }
                    }
                }

                img {
                    max-width: 100%;
                }
            }
        }
    }
}

#homepage-partners2 {
    background-image: unset;
    background-color: #f9f9f9;

    .partners {
        .partner-wrapper {
            .partner-inner {
                a {
                    padding: 10px;

                    &:before {
                        display: none;
                    }
                }
            }
        }

        .partner-title {
            display: block;
            margin-top: 0.25rem;
            font-weight: bold;
            text-decoration: none;
            color: $primary-color;
        }
    }
}

#homepage-references {
    background-color: #fefefe;

    #references {
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0 0.25em 1.5em rgba(0, 0, 0, 0.2);

        a {
            text-decoration: none;
        }

        .reference-wrapper {
            text-decoration: none;
            padding: 1rem 2rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: #fff;
            flex-wrap: wrap;
            gap: 10px;

            @include media-breakpoint-up(lg) {
                gap: 0;
            }

            &.last {
                border-bottom: 0;
            }

            &:hover {
                position: relative;
                box-shadow: 0 0.25em 1.5em rgba(0, 0, 0, 0.2);
            }

            .reference-name {
                color: #fff;
                padding: 0.5rem 0.75rem;
                margin-right: 1rem;
                background-color: $primary-color;
            }

            .reference-company {
                font-size: 1.25rem;
                font-weight: bold;
                color: $primary-color;
            }

            .reference-date {
                color: #777;
                margin-left: auto;
            }
        }
    }
}

#homepage-decoration {
    background-image: url(../img/homepage-slider-2.webp);
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
}

.image-wrapper {
    padding: 10px;
    width: 100%;
    height: 300px;
    position: relative;

    &:before {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        top: 20px;
        bottom: -10px;
        left: -10px;
        right: 20px;
        background-image: linear-gradient(220deg, lighten($primary-color, 5%), darken($primary-color, 2%));
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

#home-contact {
    input,
    select {
        border-radius: 0;
    }

    form {
        height: 100%;
        padding: 2rem;
        background: #f7f7f7;
    }

    .contact-logo {
        text-align: center;
        margin-bottom: 1rem;

        img {
            max-width: 100%;
            height: auto;
        }
    }

    .contact-details {
        p {
            color: #5e5e5e;
            font-size: 18px;
            line-height: 30px;

            a {
                color: $primary-color;
            }
        }
    }

    .button-2 {
        color: #fff;
        padding: 0.5rem 1rem;
        background-color: $primary-color;
        border: 2px solid darken($primary-color, 10%);

        &:hover {
            color: $primary-color;
            background-color: #fff;
        }
    }
}

#before-footer {
    padding-top: 1rem;
    padding-bottom: 75px;
    padding-top: 25px;
    position: relative;
    z-index: 1;
    color: #fff;
    background-color: darken($primary-color, 5%);

    a {
        color: #fff;
        text-decoration: none;
    }

    &:after {
        content: "";
        display: block;
        position: absolute;
        top: -25px;
        bottom: 25px;
        left: 0;
        right: 0;
        z-index: -1;

        transform: skewY(-1deg);
        background-color: darken($primary-color, 5%);
    }

    .short-address-box {
        display: flex;
        align-items: center;
        justify-content: center;

        .short-address-box-icon {
            font-size: 2.5rem;
            margin-right: 1rem;
        }

        .short-address-box-text {
            font-size: 1.25rem;

            strong {
                display: block;
            }
        }
    }
}

footer#footer {
    padding-top: 0.5rem;
    padding-bottom: 2rem;
    position: relative;
    z-index: 2;
    color: #fff8;
    background-color: darken($primary-color, 10%);

    &:after {
        content: "";
        display: block;
        position: absolute;
        top: -25px;
        bottom: 15px;
        left: 0;
        right: 0;
        z-index: -1;

        transform: skewY(1deg);
        background-color: darken($primary-color, 10%);
    }
}

.cryptedmail {
    &:after {
        content: attr(data-name) "@" attr(data-domain) "." attr(data-tld);
    }
}

.grecaptcha-badge {
    z-index: 10;
}

.toast-alert {
    position: fixed;
    max-width: 500px;
    z-index: 100;
    top: 140px;
    left: 50%;
    border-radius: 12px;
    background: #fff;
    padding: 20px 35px 20px 25px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    transform: translateX(-50%);
    transition: all 0.75s ease;
    opacity: 0;
    visibility: hidden;

    &.active {
        opacity: 1;
        visibility: visible;
    }

    .toast-content {
        display: flex;
        align-items: center;

        .check {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 35px;
            min-width: 35px;
            background-color: #4070f4;
            color: #fff;
            font-size: 20px;
            border-radius: 50%;
        }

        .error {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 35px;
            min-width: 35px;
            background-color: #cc0000;
            color: #fff;
            font-size: 20px;
            border-radius: 50%;
        }

        .message {
            display: flex;
            flex-direction: column;
            margin: 0 20px;
        }
    }

    .text {
        font-size: 16px;
        font-weight: 400;
        color: #666666;

        &.text-1 {
            font-weight: 600;
            color: #333;
        }
    }

    .close {
        position: absolute;
        top: 10px;
        right: 15px;
        padding: 5px;
        cursor: pointer;
        opacity: 0.7;

        &:hover {
            opacity: 1;
        }
    }

    .progress {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 3px;
        width: 100%;

        &:before {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            height: 100%;
            width: 100%;
            background-color: #4070f4;
        }

        &.active {
            &:before {
                animation: progress 5s linear forwards;
            }
        }
    }

    &.success {
        .close {
            color: #4070f4;
        }
    }

    &.error {
        .progress {
            &:before {
                background-color: #cc0000;
            }
        }

        .close {
            color: #cc0000;
        }
    }
}

@keyframes progress {
    100% {
        right: 100%;
    }
}

.form-control {
    border-radius: 0;

    &.error {
        border-color: #cc0000;
    }

    & + .form-error {
        color: #cc0000;
    }
}
